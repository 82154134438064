<template>
	<div id="summary">
		<v-container v-if="!loading">
			<v-row>
				<div class="col-md-12 col-sm-12">
					<v-alert text color="#4CAF50" border="left" class="pa-2">
						<v-icon color="#4CAF50">mdi-checkbox-marked-circle</v-icon>
						{{
							showMessage
							? $t("this_request_has_already_been_signed")
							: $t("doc_signed_correctly")
						}}
					</v-alert>
				</div>
			</v-row>
			<div id="customer-alert">
				<v-card outlined class="pa-1">
					<v-row>
						<div class="col-md-12 col-xs-12">
							<v-list two-line>
								<v-list-item background-color="white">
									<v-list-item-content>
										<v-list-item-title class="mb-1">
											<h3>{{ title }}</h3>
										</v-list-item-title>
										<v-list-item-subtitle class="color_gray--text font-weight-medium">
											{{ signatureTransactionData.ownerName }}
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
								<v-list-item>
									<h4>{{ $t("signers") }}</h4>
									<v-avatar color="#F36E21" class="ml-6" size="16">
										<span class="white--text">{{ signatureCount }}</span>
									</v-avatar>
								</v-list-item>
							</v-list>
						</div>
					</v-row>
					<v-row>
						<div class="col-md-4 col-sm-12" v-for="(item, index) in signatureRequestList" :key="index">
							<v-list class="pa-0">
								<v-list-item>
                                        <v-list-item-action>
                                            <v-icon v-if="item.status === 'SIGNED' || item.status === 'PRESIGNED'"
                                                :color="getIconColorByStatus('SIGNED')">
                                                mdi-checkbox-marked-circle-outline</v-icon>
                                            <v-icon v-if="item.status === 'REJECTED'" :color="getIconColorByStatus('REJECTED')">
                                                mdi-close-outline</v-icon>
                                            <v-icon v-if="item.status == 'NOTIFIED'" :color="getIconColorByStatus('NOTIFIED')">
                                                mdi-clock</v-icon>
                                        </v-list-item-action>
									<v-list-item-content class="pa-0">
										<v-list-item-subtitle class="color_gray--text font-weight-medium">
											{{ item.name }}
										</v-list-item-subtitle>
										<p>{{ moment(item.date).format("DD/MM/YYYY H:m") }}</p>
										<p>{{ item.comments }}</p>
									</v-list-item-content>

								</v-list-item>
							</v-list>
						</div>
					</v-row>
				</v-card>
			</div>
			<v-row>
				<div class="offset-md-5 col-md-2 col-xs-12 mt-5 pb-5">
					<v-btn large color="#F36E21" :loading="loadingDownload" block dark
						@click="downloadSignatureRequestDocument()"
						v-if="!hasPendingSigners">
						{{ $t("download") }}
					</v-btn>
				</div>
                <!-- ✅ Mostrar el texto solo si localStorage.list es "true" -->
			</v-row>
			<v-row>
                <div v-if="showBackToList" class="text-center mt-3">
                    <v-btn text @click="isLogging" color="primary">
                        {{ $t("back_to_documents_list") }}
                    </v-btn>
                </div>
            </v-row>
			<v-row>
				<div class="col-md-12 col-xs-12 mt-1">
					<v-alert color="#F36E21" dark icon="mdi-fountain-pen-tip" prominent>
						{{ $t("summary_msg") }}.
						<p class="mb-0">
							{{ $t("summary_for_more") }}</p>
						<p class="text-center"><a href="https://www.portasigma.com/" target="_blank" class="grey--text text--darken-4"
								rel="noopener">https://www.portasigma.com</a>
						</p>
					</v-alert>
				</div>
			</v-row>
		</v-container>
		<v-container style="height: 400px" v-if="loading">
			<v-row class="fill-height" align-content="center" justify="center">
				<v-col class="subtitle-1 text-center" cols="12">
					{{ $t("please_wait") }} ....
				</v-col>
				<v-col cols="6">
					<v-progress-linear color="deep-purple accent-4" indeterminate rounded height="6"></v-progress-linear>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import { SignaturesService } from "../services/signatures-service";

import moment from "moment";
export default {
	name: "important-information",
	props: {
		showMessage: String,
	},
	data: function () {
		return {
			documentId: 2406330,
			appendFils: false,
			title: "",
			document: [],
			signatureTransactionData: [],
			signatureRequestList: [],
			signatureCount: 0,
			requestId: localStorage.getItem("request_id"),
			transactionId: localStorage.getItem("transaction_id"),
			loading: false,
			loadingDownload: false,
			authId: localStorage.getItem("auth_id"),
			hasPendingSigners: false,
			userToken: localStorage.getItem("auth_user_token"),
		};
	},

	created: function () {
		this.moment = moment;
	},

	computed: {
        showBackToList() {
            let back;
            let user = localStorage.getItem("user");
            let list = localStorage.getItem("list");
            if (user || list) {
                back = true;
                }
            else {
                back = false;
                }
            return back;
        }
    },

	mounted() {
		this.loadRequestData();
	},

	methods: {
        isLogging() {
            this.authid="";
            let association=localStorage.getItem("currentAssociation");
            let isCustomized=localStorage.getItem("isCustomizedUser");
            console.log(association);
            console.log(localStorage.getItem("auth_user_token"));

            if (localStorage.getItem("auth_user_token") != "") {
                this.$router.push({
                    name: isCustomized ? "custom-my-signatures" : "my-signatures",
                    params: isCustomized ? { association } : {}
                });

            } else {
                return false;
            }
        },
		summaryMessage() {
			return this.showMessage
				? this.$t("this_request_has_already_been_signed")
				: this.$t("doc_signed_correctly")
		},
		changeAppendFileShow() {
			this.appendFils = !this.appendFils;
		},

		loadRequestData() {
			if (this.requestId) {
				this.loading = true;
				SignaturesService.getSignatureRequestData(this.requestId, this.authId)
					.then((response) => {
						this.signatureTransactionData = response.data.signatureTransaction;
						this.document = response.data;
						this.title = this.signatureTransactionData.description;
						this.createRequestList(response.data);
						this.loading = false;
						localStorage.setItem("file_name", this.signatureTransactionData.document.filename);
					})
					.catch((error) => {
						console.log(error);
						this.loading = false;
					});
			}
		},

		createRequestList(data) {
			var vm = this;
			vm.signatureRequestList.push({
				name: data.signerName,
				date: data.dateAction,
				status: data.status,
				comments: data.comments,
			});

			if (data.signatureTransaction.signatureRequests.length > 1) {
				vm.hasPendingSigners = false;
				data.signatureTransaction.signatureRequests.map(function (item) {
					if (item.signerName && item.signerName !== "") {
						vm.signatureRequestList.push({
							name: item.signerName,
							date: item.dateAction,
							status: item.status,
							comments: item.comments,
						});

						if (item.status == 'NOTIFIED') {
							vm.hasPendingSigners = true
						}
					}
				});
			}
			this.signatureCount = this.signatureRequestList.length;
		},

		downloadSignatureRequestDocument() {
			if (this.transactionId) {
				this.loadingDownload = true;
				SignaturesService.getSignatureRequestDocument(
					this.requestId,
					this.authId
				)
					.then((response) => {
						var file = new Blob([response.data], { type: "application/pdf" });
						var fileURL = URL.createObjectURL(file);
						var a = document.createElement("a");
						a.href = fileURL;
						a.download = localStorage.getItem("file_name");
						a.click();
						window.URL.revokeObjectURL(fileURL);
						this.loadingDownload = false;
					})
					.catch((error) => {
						console.log(error);
						this.loadingDownload = false;
					});
			}
		},

		getIconColorByStatus(type) {
			let color = "";
			switch (type) {
				case "SIGNED":
					color = "#4CAF50";
					break;

				case "REJECTED":
					color = "#970606";
					break;

				case "NOTIFIED":
					color = "#E0E0E0";
					break;
			}
			return color;
		},
	},
};
</script>
